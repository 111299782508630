import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Layout from "../components/global/layout";
import SEO from "../components/global/seo";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Container from "../components/comp-layouts/Container";
import Owl from "../components/comp-layouts/Owl";
import Button from "../components/Button";
import theme from "../theme/theme.json";
import * as Icon from "react-feather";

const PostTemplate = ({ data, pageContext }) => {
  const { previous, next } = pageContext;
  // const image = getImage(data.markdownRemark.frontmatter.image);
  return (
    <>
      <SEO
        title={data.markdownRemark.frontmatter.title}
        description={data.markdownRemark.frontmatter.description}
        image={data.markdownRemark.frontmatter.image.publicURL}
        slug={`/news${data.markdownRemark.fields.slug}`}
      />
      <Layout>
        <Container mt="60px" mb="60px">
          <Owl margin="2.5rem">
            <PostTitle>{data.markdownRemark.frontmatter.title}</PostTitle>
            {/* <BlogImg image={image} alt={data.markdownRemark.frontmatter.alt} /> */}
            <PostDate>
              <Icon.Calendar />{" "}
              <span style={{ margin: "0 0 0 10px" }}>
                {data.markdownRemark.frontmatter.date}
              </span>
            </PostDate>
            <div
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />

            <Divider>
              <Icon.ChevronsDown color={theme.col.bluegrey[700]} />
            </Divider>
          </Owl>
          {!previous && (
            <FirstBlogListNavContainer>
              <NavBlockRight to={`/news${next.fields.slug}`}>
                <NavLabel>Next »</NavLabel>
                <NavTitle>{next.frontmatter.title}</NavTitle>
              </NavBlockRight>
            </FirstBlogListNavContainer>
          )}

          {previous && (
            <BlogListNavLinkContainer>
              {previous && (
                <NavBlockLeft to={`/news${previous.fields.slug}`}>
                  <NavLabel>« Previous</NavLabel>
                  <NavTitle>{previous.frontmatter.title}</NavTitle>
                </NavBlockLeft>
              )}
              {next && (
                <div>
                  <NavBlockRight to={`/news${next.fields.slug}`}>
                    <NavLabel>Next »</NavLabel>
                    <NavTitle>{next.frontmatter.title}</NavTitle>
                  </NavBlockRight>
                </div>
              )}
            </BlogListNavLinkContainer>
          )}
        </Container>
      </Layout>
    </>
  );
};

export default PostTemplate;

export const PostCMS = ({ title, image, date, body }) => (
  <main>
    <Container mt="60px" mb="60px">
      <Owl>
        <PostTitle>{title}</PostTitle>
        <PostDate>
          <Icon.Calendar /> <span style={{ margin: "0 0 0 10px" }}>{date}</span>
        </PostDate>
        <div>{body}</div>
      </Owl>
    </Container>
  </main>
);

export const newsPostQuery = graphql`
  query NewsPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields {
        collection
        slug
      }
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        image {
          publicURL
        }
        date(formatString: "MMMM Do, YYYY")
        description
        alt
      }
    }
  }
`;

const BlogImg = styled((props) => <GatsbyImage {...props} />)``;

const PostTitle = styled.h1`
  font-size: 2.35rem;
`;

const FirstBlogListNavContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

const BlogListNavLinkContainer = styled.div`
  @media (min-width: 600px) {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }
`;

const PostDate = styled.time`
  font-weight: bold;
  display: flex;
  width: max-content;
  background: ${theme.col.primary.dark};
  color: ${theme.col.bw.w};
  margin-right: 15px;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: ${theme.fs.sm};
`;

const NavBlockRight = styled((props) => <Link {...props} />)`
  display: block;
  text-align: right;
  text-decoration: none;
  @media (min-width: 600px) {
    max-width: 300px;
  }
`;

const NavBlockLeft = styled((props) => <Link {...props} />)`
  display: block;
  text-decoration: none;
  margin-bottom: 30px;
  @media (min-width: 600px) {
    max-width: 300px;
  }
`;

const NavLabel = styled.span`
  display: block;
  letter-spacing: 2px;
  font-family: ${theme.ff.secondary};
  color: ${theme.col.bluegrey[400]};
  text-transform: uppercase;
`;

const NavTitle = styled.span`
  display: block;
  color: ${theme.col.bw.b};
  font-weight: bold;
`;

const Divider = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 40px 0;
  & ::before {
    content: "";
    position: absolute;
    display: block;
    height: 3px;
    width: 45%;
    left: 0;
    background-color: ${theme.col.bluegrey[100]};
  }
  & ::after {
    content: "";
    position: absolute;
    display: block;
    height: 3px;
    width: 45%;
    right: 0;
    background-color: ${theme.col.bluegrey[100]};
  }
`;
